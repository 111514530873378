// Note: The first three badges will be featured on the podium

export const g2BadgeList = [
  'VideoHosting_MomentumLeader_Leader',
  'VideoHosting_Leader_Leader',
  'VideoHosting_BestUsability_Mid-Market_Total',
  'VideoHosting_BestRelationship_Mid-Market_Total',
  'VideoHosting_BestResults_Mid-Market_Total',
  'VideoHosting_EasiestToUse_Enterprise_EaseOfUse',
  'VideoHosting_MostImplementable_Enterprise_Total',
];
