'use client';

import { styled } from 'styled-components';
import * as Styled from '@lib/styles';
import type { ReactNode } from 'react';
import type { Color, CtaProps } from '@types';
import TextLink from './TextLink';
import Button from './Button';

type BaseTextProps = {
  /**
   * Heading for the base text
   */
  heading?: string;
  /**
   * Heading level
   */
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  /**
   * Body text for the base text
   */
  body?: ReactNode | string;
  /**
   * Optional className for styling
   */
  className?: string;
  /**
   * Optional CTA
   * Note: Not to be used with buttons; Meaning there should not be
   * a primary or secondary button when using this option
   */
  ctaLink?: CtaProps;
  /**
   * Is text centered? Default is false
   */
  isCentered?: boolean;
  /**
   * Primary button
   * Note: Not to be used with the CTA; Meaning there should
   * not be a link displayed when using buttons
   */
  primaryBtn?: CtaProps;
  /*
   * Secondary button
   * Note: Not to be used with the CTA; Meaning there should
   * not be a link displayed when using buttons
   */
  secondaryBtn?: CtaProps;
  /**
   * Eyebrow text
   */
  eyebrow?: string;
  /**
   * Color of the button(s)
   */
  buttonColor?: Color;
};

const BaseTextWrapper = styled.div<{ $isCentered }>`
  text-align: ${({ $isCentered }) => ($isCentered ? 'center' : 'start')};
  text-wrap: ${({ $isCentered }) => ($isCentered ? 'balance' : 'unset')};
`;

// Unstyled heading necessary to use the as prop
const Heading = styled.h2``;

const ButtonRow = styled.div<{ $isCentered }>`
  align-items: ${({ $isCentered }) => ($isCentered ? 'center' : 'start')};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[1.5]};
  justify-content: ${({ $isCentered }) => ($isCentered ? 'center' : 'start')};

  ${({ theme }) => theme.mq.minWidth.medium} {
    flex-direction: row;
  }
`;

// Needs to be a div to style spacing in consumed components/modules
const BaseText = ({
  heading,
  headingLevel = 'h2',
  body,
  className,
  ctaLink = null,
  isCentered = false,
  primaryBtn = null,
  secondaryBtn = null,
  buttonColor,
  eyebrow,
}: BaseTextProps) => {
  const computedBody = () => {
    switch (headingLevel) {
      case 'h1':
        return <Styled.BodyLg>{body}</Styled.BodyLg>;
      case 'h6':
      case 'h5':
        return <Styled.BodyXs>{body}</Styled.BodyXs>;
      case 'h4':
        return <Styled.BodySm>{body}</Styled.BodySm>;
      case 'h3':
        return <Styled.BodyMd>{body}</Styled.BodyMd>;
      default:
        return <p>{body}</p>;
    }
  };

  const computedHref = () => {
    switch (headingLevel) {
      case 'h1':
        return (
          <Styled.BodyLg>
            <TextLink href={ctaLink.href}>{ctaLink.text}</TextLink>
          </Styled.BodyLg>
        );
      case 'h6':
      case 'h5':
        return (
          <Styled.BodyXs>
            <TextLink href={ctaLink.href}>{ctaLink.text}</TextLink>
          </Styled.BodyXs>
        );
      case 'h4':
        return (
          <Styled.BodySm>
            <TextLink href={ctaLink.href}>{ctaLink.text}</TextLink>
          </Styled.BodySm>
        );
      case 'h3':
        return (
          <Styled.BodyMd>
            <TextLink href={ctaLink.href}>{ctaLink.text}</TextLink>
          </Styled.BodyMd>
        );
      default:
        return (
          <p>
            <TextLink href={ctaLink.href}>{ctaLink.text}</TextLink>
          </p>
        );
    }
  };

  return (
    <BaseTextWrapper $isCentered={isCentered} className={className}>
      {eyebrow ? <Styled.Eyebrow>{eyebrow}</Styled.Eyebrow> : null}
      {heading ? <Heading as={headingLevel}>{heading}</Heading> : null}
      {body ? computedBody() : null}
      {primaryBtn || secondaryBtn ? (
        <ButtonRow $isCentered={isCentered}>
          {primaryBtn ? (
            // Primary Button in BaseText is always brand blue according to Figma
            <Button
              color="blue500"
              href={primaryBtn.href}
              trackingLabel={primaryBtn.trackingLabel}
              trackingProperty={primaryBtn.trackingProperty}
            >
              {primaryBtn.text}
            </Button>
          ) : null}
          {secondaryBtn ? (
            <Button
              color={buttonColor}
              href={secondaryBtn.href}
              trackingLabel={secondaryBtn.trackingLabel}
              trackingProperty={secondaryBtn.trackingProperty}
              variant="secondary"
            >
              {secondaryBtn.text}
            </Button>
          ) : null}
        </ButtonRow>
      ) : null}
      {ctaLink ? computedHref() : null}
    </BaseTextWrapper>
  );
};

export default BaseText;
