'use client';

import React from 'react';
import { styled } from 'styled-components';

type SplitContentPanelProps = {
  /**
   * The content that sits opposite the media/video
   */
  children: React.ReactNode;
  /**
   * Optional classname for extending styles
   */
  className?: string;
};

export const SplitContent = styled.div`
  grid-row: 2;
  ${({ theme }) => theme.mq.minWidth.medium} {
    grid-row: 1;
  }
  ${({ theme }) => theme.mq.minWidth.large} {
    grid-row: 1;
  }
`;

const SplitContentPanel = ({ children, className }: SplitContentPanelProps) => (
  <SplitContent className={className}>{children}</SplitContent>
);

export default SplitContentPanel;
