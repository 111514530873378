'use client';

import type { ReactNode } from 'react';
import SplitContentPanel from '@dvd/components/SplitContentPanel';
import SplitMediaPanel from '@dvd/components/SplitMediaPanel';
import BaseText from '@dvd/components/BaseText';
import type { SplitProps } from '@types';
import SplitGrid from '.';

type SplitTextProps = SplitProps & {
  /**
   * Optional eyebrow text above the headline
   */
  eyebrowText?: string;
  /**
   * Optional children/layouts used for variants like SplitCard
   */
  children?: ReactNode;
};

const SplitText = ({
  children,
  description,
  eyebrowText,
  hashedId,
  headline,
  imageAlt,
  imageHeight,
  imageSrc,
  imageWidth,
  mediaOrientation,
  primaryBtn = null,
  secondaryBtn = null,
  ctaLink = null,
}: SplitTextProps) => (
  <SplitGrid mediaOrientation={mediaOrientation}>
    {hashedId ? (
      <SplitMediaPanel hashedId={hashedId} />
    ) : (
      <SplitMediaPanel
        alt={imageAlt}
        height={imageHeight}
        src={imageSrc}
        width={imageWidth}
      />
    )}
    <SplitContentPanel>
      <BaseText
        body={description}
        ctaLink={ctaLink}
        eyebrow={eyebrowText}
        heading={headline}
        headingLevel="h3"
        primaryBtn={primaryBtn}
        secondaryBtn={secondaryBtn}
      />
      {children}
    </SplitContentPanel>
  </SplitGrid>
);

export default SplitText;
