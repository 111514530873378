'use client';

import { styled } from 'styled-components';

const logoList = [
  'hubspot',
  'adobe',
  'pardot',
  'mailchimp',
  'activecampaign',
  'keap',
  'googleanalytics',
  'hootsuite',
  'facebook',
  'linkedin',
  'x',
  'youtube',
  'zoom',
];

const LogoShowcaseContainer = styled.div`
  column-gap: ${({ theme }) => theme.spacing[3.75]};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-block-end: ${({ theme }) => theme.spacing[8]};
  row-gap: ${({ theme }) => theme.spacing[2.5]};
`;
/**
 * Component to display integration logos
 */
const LogoShowcase = () => {
  const logoItems = logoList.map((logoName) => {
    return (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        key={logoName}
        alt={logoName}
        src={`/reno_static/images/showcaseLogos/${logoName}.svg`}
      />
    );
  });
  return <LogoShowcaseContainer>{logoItems}</LogoShowcaseContainer>;
};

export default LogoShowcase;
