'use client';

import { styled } from 'styled-components';
import type { MediaContentProps } from '@dvd/components/Media';
import Media from '@dvd/components/Media';

export const SplitMedia = styled.div`
  grid-row: 1;

  & > * {
    margin-block-start: 0;
    margin-block-end: ${({ theme }) => theme.spacing[2]};
  }

  ${({ theme }) => theme.mq.minWidth.large} {
    & > * {
      margin-block: 0;
    }
  }
`;

const SplitMediaPanel = ({
  hashedId,
  src,
  alt,
  height,
  width,
}: MediaContentProps) => (
  <SplitMedia>
    {hashedId ? (
      <Media hashedId={hashedId} />
    ) : (
      <Media alt={alt} height={height} src={src} width={width} />
    )}
  </SplitMedia>
);

export default SplitMediaPanel;
