'use client';

import type { ReactNode } from 'react';
import cs from 'classnames';
import { styled } from 'styled-components';
import { InView } from 'react-intersection-observer';
import * as Styled from '@lib/styles';
import TextLink from '@dvd/components/TextLink';
import Media from '@dvd/components/Media';
import type { ThreeChildren, FourChildren } from '@types';

/* TODO: refactor when comes time to better use BaseText */
type ScrollingChildProps = {
  /**
   * What the block headline says
   */
  headline: string;
  /**
   * Descriptive paragraph for the block
   */
  description: ReactNode;
  /**
   * The hashed id of the media; nesc. for showing a media
   */
  hashedId?: string;
  /**
   * URL of image; nesc. for showing an image
   */
  imageSrc?: string;
  /**
   * Alt text for image; nesc. for showing an image
   */
  imageAlt?: string;
  /**
   * Optional link for the block
   */
  ctaHref?: string;
  /**
   * Optional link text for the block
   */
  ctaText?: string;
};

const BlockContent = styled.div`
  h2,
  p {
    margin-block-end: ${({ theme }) => theme.spacing[0.75]};
  }

  /** add some extra breathing room before the next block on mobile */
  padding-block-end: ${({ theme }) => theme.spacing[4]};

  /** Minimize breathing room on first one on mobile */
  &:first-child {
    padding-block-end: ${({ theme }) => theme.spacing[1]};
  }
  ${({ theme }) => theme.mq.minWidth.medium} {
    padding-block-end: 0;

    &:first-child {
      padding-block-end: 0;
    }
  }
`;

// Note: used to attach progressive enancement styles for the medium-screen+ scrolling effect
const BlockMedia = styled.div`
  & > div:first-child {
    margin: 0;
  }

  ${({ theme }) => theme.mq.minWidth.medium} {
    padding-block-start: ${({ theme }) => theme.spacing[2]};
  }
`;

export const ScrollingChild = ({
  headline,
  description,
  ctaHref,
  ctaText = 'Learn more',
  imageSrc,
  imageAlt,
  hashedId,
}: ScrollingChildProps) => {
  return (
    <InView threshold={0.8}>
      {({ inView, ref }) => (
        <>
          <BlockContent ref={ref}>
            <h3>{headline}</h3>
            <p>{description}</p>
            {ctaHref ? <TextLink href={ctaHref}>{ctaText}</TextLink> : null}
          </BlockContent>
          <BlockMedia className={cs({ active: inView })}>
            {hashedId ? (
              <Media hashedId={hashedId} />
            ) : (
              <Media alt={imageAlt} height={583} src={imageSrc} width={528} />
            )}
          </BlockMedia>
        </>
      )}
    </InView>
  );
};

/* TODO: refactor when comes time to better use BaseText */
/* TODO: I've seen both [Component, Component, Component] and ThreeChildren...
 * is there a better way to type the individual items? */
type ScrollingProps = {
  /**
   * What the introductory headline says
   */
  headline: string;
  /**
   * Descriptive paragraph introducing the scrolling slides
   */
  description?: string;
  /**
   * Optional link for the module
   */
  ctaHref?: string;
  /**
   * Optional link text for the module
   */
  ctaText?: string;
  /**
   * Three or four ScrollingChild(ren)
   */
  children: FourChildren | ThreeChildren;
  /**
   * Optional class name, nesc. for extending as a styled-component
   */
  className?: string;
};

/* TODO: create a function for max-width value. It's derived from 8 columns in the 12 column grid */
const ScrollingMessaging = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[1.5]};
  margin-inline: auto;
  max-width: 54rem;
  text-align: center;

  h2,
  p {
    margin: 0;
  }
`;

const ScrollingBlocks = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: ${({ theme }) => theme.spacing[2]};

  /** Note: we want to switch to grid view on tablet, but use the column structure of the L & XL */
  ${({ theme }) => theme.mq.minWidth.medium} {
    align-items: center;
    display: grid;
    flex-direction: column;
    ${({ theme }) => theme.grid.columns.large}
    grid-auto-rows: 1fr;
    row-gap: ${({ theme }) => theme.spacing[10]};

    ${BlockContent} {
      grid-column: 2 / span 4;
      padding-block: ${({ theme }) => theme.spacing[10]};
    }
    ${BlockMedia} {
      grid-column: 7 / span 5;
      grid-row: 1;
      opacity: 0;
      position: sticky;
      top: calc(25% - ${({ theme }) => theme.spacing[4]});
      transition: opacity 333ms ease-in-out;

      &.active {
        opacity: 1;
      }
    }
  }
`;

const ScrollingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[2]};
  margin-block: ${({ theme }) => theme.spacing[2]};
`;

/**
 * Scrolling module with automatic visual transitions for medium+ screens.
 *
 * Note: Practically speaking, should be lower on the page. But technically speaking, no
 * guard-rails against other usage.
 */
const Scrolling = ({
  headline,
  description,
  ctaHref,
  ctaText = 'Learn more',
  children,
}: ScrollingProps) => {
  return (
    <ScrollingContainer>
      <ScrollingMessaging>
        <h2>{headline}</h2>
        {description ? <Styled.BodyLg>{description}</Styled.BodyLg> : null}
        {ctaHref ? <TextLink href={ctaHref}>{ctaText}</TextLink> : null}
      </ScrollingMessaging>
      <ScrollingBlocks>{children}</ScrollingBlocks>
    </ScrollingContainer>
  );
};

export default Scrolling;
