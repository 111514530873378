'use client';

import { styled } from 'styled-components';
import G2Badges from '@dvd/components/G2Badges';
import TextLink from '@dvd/components/TextLink';
import type { FourChildren } from '@types';
import { visuallyHiddenStyles } from '@lib/styles';

type G2SocialProofProps = {
  /**
   * Optional four testimonial cards as children
   */
  children?: FourChildren;
  /**
   * Optional cta text
   */
  ctaText?: string;
  /**
   * Heading for the module
   */
  heading: string;
  /**
   * Optional href for the cta
   */
  href?: string;
  /**
   * Show all badges scrolling section? Default is true.
   */
  showAllBadges?: boolean;
  /**
   * Show podium of top three badges? Default is true.
   */
  showPodium?: boolean;
   /**
   * Hide component on mobile screens. Default is false.
   */
   hideOnMobile?: boolean;
};

const G2SocialProofModule = styled.div<{ $hideOnMobile?: boolean }>`
  padding-block: ${({ theme }) => theme.spacing[3]};

  ${({ $hideOnMobile, theme }) =>
    $hideOnMobile &&
    `
    ${visuallyHiddenStyles};

    ${theme.mq.minWidth.small} {
      display: block;
    }
  `}


  ${({ theme }) => theme.mq.minWidth.large} {
    padding-block: ${({ theme }) => theme.spacing[4]};
  }
`;

/* TODO: replace with BaseText implementation once refactored */
const G2SocialProofHeading = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const G2SocialProofCards = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[2]};

  ${({ theme }) => theme.mq.minWidth.large} {
    align-items: start;
    flex-direction: row;
    justify-content: center;
  }
`;

const G2SocialProof = ({
  children,
  ctaText = 'Learn more',
  heading,
  hideOnMobile = false,
  href,
  showPodium = true,
  showAllBadges = true,
}: G2SocialProofProps) => (
  <G2SocialProofModule $hideOnMobile={hideOnMobile}>
    <G2SocialProofHeading>
      <h2>{heading}</h2>
      {href ? <TextLink href={href}>{ctaText}</TextLink> : null}
    </G2SocialProofHeading>
    <G2Badges showAllBadges={showAllBadges} showPodium={showPodium} />
    {children ? <G2SocialProofCards>{children}</G2SocialProofCards> : null}
  </G2SocialProofModule>
);

export default G2SocialProof;
