'use client';

import { styled } from 'styled-components';
import cs from 'classnames';
import Video from '@dvd/components/Video';
import * as Styled from '@lib/styles';
import BaseText from '@dvd/components/BaseText';
import type { ThreeChildren, FourChildren, CtaProps } from '@types';

type GiantVideoProps = {
  /**
   * Should the module have bottom padding; default is true
   */
  bottomPadding?: boolean;
  /**
   * child components for the module; must be either 3 or 4
   */
  children?: FourChildren | ThreeChildren;
  /**
   * Description text for the module
   */
  description?: string;
  /**
   * Eyebrow text for the module
   */
  eyebrowText?: string;
  /**
   * Toggle to true if this is the first module on the page
   */
  isPrimaryModule?: boolean;
  /**
   * Hashed id of the giant video
   */
  hashedId: string;
  /**
   * headline of the module
   */
  headline: string;
  /**
   * Optional link CTA. NOTE: Not to be used with any buttons.
   */
  ctaLink?: CtaProps;
  /**
   * Optional primary button CTA. NOTE: Not to be used with link.
   */
  primaryBtn?: CtaProps;
  /**
   * Optional secondary button CTA. NOTE: Not to be used with link.
   */
  secondaryBtn?: CtaProps;
  /**
   * Variant choices
   *  - textWide - wide grid with text
   *  - textDefault - standard layout with text
   *  - cardWide - wide grid with 4 cards
   *  - cardDefault - standard layout with 3 cards
   */
  variant?: 'cardDefault' | 'cardWide' | 'textDefault' | 'textWide';
};

const GiantVideoMedia = styled(Video)`
  height: 100%;
  margin: ${({ theme }) => theme.spacing[2]} auto;
  width: 100%;
`;

const Blocks = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[1]};
`;

const GiantVideoContainer = styled.div`
  &.isPrimaryModule {
    padding-block-start: ${({ theme }) => theme.spacing[4]};
  }
  ${({ theme }) => theme.mq.minWidth.large} {
    display: grid;
    ${({ theme }) => theme.grid.columns.large};

    &.isPrimaryModule {
      padding-block-start: 0;
    }

    &.bottomPadding {
      padding-block-end: ${({ theme }) => theme.spacing[4]};
    }

    & ${GiantVideoMedia} {
      grid-column: 1 / span 12;
    }

    &.textDefault ${GiantVideoMedia} {
      grid-column: 2 / span 10;
    }

    &.textWide ${Blocks}, &.textDefault ${Blocks} {
      display: flex;
      flex-direction: row;
      gap: ${({ theme }) => theme.spacing[3]};
      grid-column: 2 / span 10;
      justify-content: space-between;
    }

    &.cardWide ${Blocks} {
      column-gap: 2rem;
      display: grid;
      grid-column: 1 / span 12;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: subgrid;
    }

    &.cardDefault ${Blocks} {
      column-gap: 2rem;
      display: grid;
      grid-column: 1 / span 12;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: subgrid;
    }
  }
`;

const GiantVideoFullText = styled.div`
  text-align: center;

  ${({ theme }) => theme.mq.minWidth.large} {
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-column: 2 / span 10;
    justify-content: center;
    padding-block-start: ${({ theme }) => theme.spacing[4]};
    place-self: center;
    text-wrap: balance;
  }
`;

const StyledParagraphHeadline = styled.p`
  font-family: var(--font-walsheim);
  font-size: ${({ theme }) => theme.typography.fontSize.headingOne};
  font-weight: ${({ theme }) => theme.typography.fontWeight.headingOne};
  line-height: ${({ theme }) => theme.typography.lineHeight.headingOne};

  ${({ theme }) => theme.mq.minWidth.large} {
    font-size: ${({ theme }) => theme.typography.fontSize.desktop.headingOne};
  }
`;

const StyledEyebrow = styled(Styled.Eyebrow)`
  margin-block-end: 0;
`;

const GiantVideo = ({
  bottomPadding = true,
  children,
  eyebrowText,
  isPrimaryModule = false,
  description,
  hashedId,
  headline,
  ctaLink = null,
  primaryBtn = null,
  secondaryBtn = null,
  variant = 'textDefault',
}: GiantVideoProps) => {
  return (
    <GiantVideoContainer
      className={cs(variant, {
        isPrimaryModule,
        bottomPadding,
      })}
    >
      <GiantVideoFullText>
        {isPrimaryModule ? (
          <>
            {eyebrowText ? (
              <StyledEyebrow as="h1">{eyebrowText}</StyledEyebrow>
            ) : null}
            <StyledParagraphHeadline>{headline}</StyledParagraphHeadline>
          </>
        ) : (
          <>
            {eyebrowText ? (
              <Styled.Eyebrow>{eyebrowText}</Styled.Eyebrow>
            ) : null}
            <h2>{headline}</h2>
          </>
        )}
        {/* Use BaseText for remaining Giant Video elements */}
        <BaseText
          body={description}
          ctaLink={ctaLink}
          isCentered={true}
          primaryBtn={primaryBtn}
          secondaryBtn={secondaryBtn}
        />
      </GiantVideoFullText>

      <GiantVideoMedia hashedId={hashedId} />

      {children ? <Blocks>{children}</Blocks> : null}
    </GiantVideoContainer>
  );
};

export default GiantVideo;
