'use client';

import React from 'react';
import { styled } from 'styled-components';
import cs from 'classnames';
import { SplitContent } from '@dvd/components/SplitContentPanel';
import { SplitMedia } from '@dvd/components/SplitMediaPanel';
import type { Orientation } from '@types';

const GridLayout = styled.div`
  align-items: center;
  display: grid;
  ${({ theme }) => theme.grid.columns.small};
  margin-block: ${({ theme }) => theme.spacing[3]};

  ${SplitMedia}, ${SplitContent} {
    grid-column: auto / span 2;
  }

  ${({ theme }) => theme.mq.minWidth.medium} {
    ${({ theme }) => theme.grid.columns.medium};

    &.start ${SplitMedia} {
      grid-column: 1 / span 3;
    }

    &.end ${SplitMedia} {
      grid-column: 4 / span 3;
    }

    &.start ${SplitContent} {
      grid-column: 4 / span 3;
    }

    &.end ${SplitContent} {
      grid-column: 1 / span 3;
    }

    /** Expanded must stack on tablet views */
    &.expanded {
      ${({ theme }) => theme.grid.columns.small};
    }

    &.start.expanded ${SplitMedia} {
      grid-column: 1 / span 2;
      grid-row: 1;
    }

    &.start.expanded ${SplitContent} {
      grid-column: 1 / span 2;
      grid-row: 2;
    }

    &.end.expanded ${SplitMedia} {
      grid-column: 1 / span 2;
      grid-row: 1;
    }

    &.end.expanded ${SplitContent} {
      grid-column: 1 / span 2;
      grid-row: 2;
    }

    &:last-child {
      padding-block-end: ${({ theme }) => theme.spacing[3]};
    }
  }

  ${({ theme }) => theme.mq.minWidth.large} {
    margin-block: ${({ theme }) => theme.spacing[4]};
    ${({ theme }) => theme.grid.columns.large};

    &.start ${SplitMedia} {
      grid-column: 2 / span 5;
    }

    &.end ${SplitMedia} {
      grid-column: 7 / span 5;
    }

    &.start ${SplitContent} {
      grid-column: 8 / span 4;
    }

    &.end ${SplitContent} {
      grid-column: 2 / span 4;
    }

    &.expanded {
      ${({ theme }) => theme.grid.columns.xlarge};
    }

    &.start.expanded ${SplitMedia} {
      grid-column: 1 / span 5;
      grid-row: 1;
    }

    &.end.expanded ${SplitMedia} {
      grid-column: 8 / span 5;
      grid-row: 1;
    }

    &.start.expanded ${SplitContent} {
      grid-column: 7 / span 6;
      grid-row: 1;
    }

    &.end.expanded ${SplitContent} {
      grid-column: 1 / span 6;
      grid-row: 1;
    }
  }
`;

export type SplitGridProps = {
  /**
   * Grid components of SplitVideoPanel and SplitContentPanel
   */
  children: React.ReactNode;
  /**
   * Orientation of the video; either start or end
   */
  mediaOrientation: Orientation;
  /**
   * Is grid expanded layout?
   */
  expanded?: boolean;
};

const SplitGrid = ({
  children,
  mediaOrientation,
  expanded = false,
}: SplitGridProps) => (
  <GridLayout className={cs(mediaOrientation, { expanded })}>
    {children}
  </GridLayout>
);

export default SplitGrid;
