'use client';

import { styled } from 'styled-components';
import cs from 'classnames';
import * as Styled from '@lib/styles';
import { Icon } from '@wistia/ui';
import type { Color } from '@types';

export type TestimonialCardProps = {
  /**
   * The author of the quote on the card
   */
  attribution: string;
  /**
   * The title of the attributing author
   */
  attributionTitle: string;
  /**
   * What href should the attributionTitle point to?
   */
  attributionSourceHref?: string;
  /**
   * The background color of the card
   */
  backgroundColor?: Color | 'transparent';
  /**
   * The card headline
   */
  headline?: string;
  /**
   * The card quote
   */
  quote: string;
  /**
   * The number of the rating
   */
  rating?: number;
  /**
   * The color of the rating stars; yellow or blue
   */
  ratingColor?: 'blue' | 'yellow';
  /**
   * set to false if we should NOT show rating
   */
  showRating?: boolean;
  /**
   * The text color of the card
   */
  textColor?: Color;
  /**
   * Optional class name, nesc. for extending as a styled-component
   */
  className?: string;
  /**
   * Set to false if we should NOT show G2 as source
   */
  showCite?: boolean;
};

const Card = styled.blockquote<{
  $backgroundColor: Color | 'transparent';
  $color: Color;
}>`
  background-color: ${({ $backgroundColor, theme }) =>
    $backgroundColor === 'transparent'
      ? 'transparent'
      : theme.colors[$backgroundColor]};
  border-radius: ${({ theme }) => theme.radius.std};
  color: ${({ $backgroundColor, $color, theme }) =>
    $backgroundColor === 'transparent' ? 'inherit' : theme.colors[$color]};
  height: 100%;
  max-width: calc(100vw - ${({ theme }) => theme.spacing[2.5]});
  padding: ${({ theme }) => theme.spacing[2]};

  ${({ theme }) => theme.mq.minWidth.large} {
    /** TODO: create a function for this value
        It's value is derived from 3 columns in the 12 column grid
    */
    max-width: 304px;
  }
`;

const Rating = styled.div<{ $color: 'blue' | 'yellow' }>`
  color: ${({ $color, theme }) =>
    $color === 'blue' ? theme.colors.blue500 : theme.colors.yellow300};
  display: flex;
  gap: 5px;
  margin-bottom: ${({ theme }) => theme.spacing[1]};
`;

const Headline = styled(Styled.BodyLg)`
  margin-block-end: ${({ theme }) => theme.spacing[1]};

  & + p {
    margin-block-end: ${({ theme }) => theme.spacing[1]};
  }
`;

const Attribution = styled(Styled.BodySm)`
  margin-block-end: 0;
`;

const AttributionTitle = styled.span`
  display: block;
`;

const AttributionSource = styled(Styled.BodyXs)`
  color: inherit;
  display: block;
  margin: 0;
  text-decoration: underline;

  &[class] {
    font-weight: ${({ theme }) => theme.typography.fontWeight.body};
  }
`;

const TestimonialCard = ({
  attribution,
  attributionSourceHref,
  attributionTitle,
  backgroundColor = 'white',
  className,
  headline,
  quote,
  rating = 5,
  ratingColor = 'yellow',
  showRating = true,
  textColor = 'blue800',
  showCite = true,
}: TestimonialCardProps) => (
  <Card
    $backgroundColor={backgroundColor}
    $color={textColor}
    // The testimonialCard classname is used to apply module specific styles; e.g. CustomerStory module.
    className={cs('testimonialCard', className)}
  >
    {showRating ? (
      <Rating $color={ratingColor} aria-label={`${rating} stars`} role="img">
        {[...Array(rating)].map((_rate, index) => {
          const computedKey = `star-${index}`;

          return <Icon key={computedKey} size="lg" type="favorite" />;
        })}
      </Rating>
    ) : null}
    {headline ? (
      <Headline>
        <strong>“{headline}”</strong>
      </Headline>
    ) : null}
    <Styled.BodySm>{quote}</Styled.BodySm>
    <Attribution>
      <b>{attribution}</b>{' '}
      <AttributionTitle>{attributionTitle}</AttributionTitle>
    </Attribution>

    {showCite ? (
      <cite>
        {attributionSourceHref ? (
          <AttributionSource as="a" href={attributionSourceHref}>
            Sourced by G2
          </AttributionSource>
        ) : (
          <AttributionSource>Sourced by G2</AttributionSource>
        )}
      </cite>
    ) : null}
  </Card>
);

export default TestimonialCard;
