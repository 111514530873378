'use client';

import { styled, keyframes } from 'styled-components';
import { g2BadgeList } from '@lib/g2BadgeList';
import Image from 'next/image';

// The following magic numbers relate only to the bigger sizes for the
// badges showing up in the "podium" area. The center one is the biggest
// with the left and right ones being slightly smaller
const FEATURED_BADGE_WIDTH = 171;
const FEATURED_BADGE_HEIGHT = 192;
const PODIUM_BADGE_WIDTH = 118;
const PODIUM_BADGE_HEIGHT = 149;

const BADGE_HEIGHT = 135;
const BADGE_WIDTH = 110;

const formattedBadgeName = (badgeName) => badgeName.replace(/_/g, ' ');

type G2BadgesProps = {
  /**
   * Show the Podium element
   */
  showPodium?: boolean;
  /**
   * Show all the scrolling badges
   */
  showAllBadges?: boolean;
};

const Container = styled.div`
  background-color: transparent;
  margin: ${({ theme }) => theme.spacing[2]} auto
    ${({ theme }) => theme.spacing[3]};
  max-width: 100vw;
  position: relative;
  text-align: center;
`;

const Podium = styled.div`
  align-items: flex-end;
  display: flex;
  gap: ${({ theme }) => theme.spacing[1]};
  justify-content: center;
  margin-bottom: 0;
  transform: scale(0.7);

  ${({ theme }) => theme.mq.minWidth.large} {
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    transform: scale(1);
  }
`;

const AllBadges = styled.div`
  overflow: hidden;
  position: relative;
`;

const Badge = styled(Image)`
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
`;

const marquee = ({ theme }) => keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(calc(-100% - ${theme.spacing[3.75]}), 0);
  }
`;

const BadgeItems = styled.div`
  animation: ${marquee} 32s linear infinite;
  display: flex;
  flex-shrink: 0;
  gap: ${({ theme }) => theme.spacing[1.5]};
  justify-content: space-around;
  min-width: 100%;

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
  ${({ theme }) => theme.mq.minWidth.large} {
    gap: ${({ theme }) => theme.spacing[3]};
  }
`;

const Scroller = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[1.5]};
  overflow: hidden;
  position: relative;
  user-select: none;

  &:hover ${BadgeItems} {
    animation-play-state: paused;
  }

  ${({ theme }) => theme.mq.minWidth.large} {
    gap: ${({ theme }) => theme.spacing[3.75]};
  }
`;

const G2Badges = ({
  showPodium = true,
  showAllBadges = true,
}: G2BadgesProps) => {
  const topThree = g2BadgeList.slice(0, 3);

  return (
    <Container>
      {showPodium ? (
        <Podium>
          {topThree.map((badgeName, index) => {
            const computedHeight =
              index === 1 ? FEATURED_BADGE_HEIGHT : PODIUM_BADGE_HEIGHT;
            const computedWidth =
              index === 1 ? FEATURED_BADGE_WIDTH : PODIUM_BADGE_WIDTH;
            return (
              <Image
                key={badgeName}
                alt={formattedBadgeName(badgeName)}
                height={computedHeight}
                src={`/reno_static/images/G2/${badgeName}.svg`}
                unoptimized={true}
                width={computedWidth}
              />
            );
          })}
        </Podium>
      ) : null}
      {showAllBadges ? (
        <AllBadges>
          <Scroller>
            <BadgeItems>
              {g2BadgeList.map((badgeName) => {
                return (
                  <Badge
                    key={badgeName}
                    alt={formattedBadgeName(badgeName)}
                    height={BADGE_HEIGHT}
                    src={`/reno_static/images/G2/${badgeName}.svg`}
                    unoptimized={true}
                    width={BADGE_WIDTH}
                  />
                );
              })}
            </BadgeItems>
            {/*
            This duplicate list of badges is instrumental to simulating a
            seamless infinite scroll
            @link: https://ryanmulligan.dev/blog/css-marquee/
          */}
            <BadgeItems aria-hidden={true}>
              {g2BadgeList.map((badgeName) => (
                <Badge
                  key={badgeName}
                  alt={formattedBadgeName(badgeName)}
                  height={BADGE_HEIGHT}
                  src={`/reno_static/images/G2/${badgeName}.svg`}
                  width={BADGE_WIDTH}
                />
              ))}
            </BadgeItems>
          </Scroller>
        </AllBadges>
      ) : null}
    </Container>
  );
};

export default G2Badges;
