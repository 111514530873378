import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/content/navigation/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/dvd/components/ImageCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/dvd/components/LogoShowcase.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/dvd/components/Section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/dvd/components/TestimonialCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/dvd/components/TextLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/dvd/modules/Cta.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/dvd/modules/FullColumnText.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/dvd/modules/G2SocialProof.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/dvd/modules/GiantVideo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/dvd/modules/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/dvd/modules/ImageCardColumns.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","ScrollingChild"] */ "/usr/src/app/src/dvd/modules/Scrolling.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/dvd/modules/Splits/SplitText.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BodyLg"] */ "/usr/src/app/src/lib/styles.ts");
